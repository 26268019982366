<template>
  <ModalContainer height="sm">
    <template v-slot:body class="nn-modal-body">
      <div
        class="
          h-full
          flex flex-col
          justify-center
          md:justify-start
          items-center
        "
      >
        <img
          class="mx-auto"
          src="@/assets/img/session_expired_clock.svg"
          alt="Session expired clock"
        />
        <BaseText h1 class="mt-4">{{
          $t("LogIn_SessionExpired_Title")
        }}</BaseText>
        <BaseText class="mt-4">{{ $t("LogIn_SessionExpired_Text") }}</BaseText>
        <div class="mt-8">
          <BaseButton primary @click="$store.dispatch('modal/hide')">{{
            $t("General_Understand")
          }}</BaseButton>
        </div>
      </div>
    </template>
  </ModalContainer>
</template>

<script>
export default {};
</script>

<style></style>
